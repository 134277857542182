import FooterColumn from "./FooterColumn";

const FeaturesFooterLinks = () => {
  const FEATURESLINKS = [
    { title: "Features" },
    { title: "Podcast Credits", to: "/creators" },
    { title: "Podcast Lists", to: "/lists" },

    { title: "Podcast Monitoring", to: "/alerts" },
    {
      title: "Podcast Sponsors",
      to: "/brands",
    },
    {
      title: "Podcast Contacts",
      to: "https://features.podchaser.com/contacts/",
      external: true,
    },
    { title: "Community", to: "/leaderboard/reviews" },
  ];

  return <FooterColumn links={FEATURESLINKS} />;
};

export default FeaturesFooterLinks;
