import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo, useCallback } from "react";
import { Link } from "react-router-dom";

import { userHasPermission } from "utils/entity/user";
import eventIsFieldTrigger from "utils/misc/eventIsFieldTrigger";

import useLoggedInUser from "hooks/useLoggedInUser";
import { useStyles } from "hooks/useStyles";

import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  linksColumn: {
    flex: 1,
    [ScreenSizes.mdAndBelow]: {
      display: "flex",
      flexDirection: "column",
      flex: "1 50%",
      alignItems: "center",
      marginBottom: "1rem",
      textAlign: "center",
    },
    [ScreenSizes.smAndBelow]: {
      display: "flex",
      flexDirection: "column",
      flex: "1 50%",
      alignItems: "start",
      marginBottom: "1rem",
      textAlign: "start",
    },
  },
  headerLink: {
    ...gStyles.avalonBold,
    fontSize: ".875rem",
    lineHeight: "2.5em",
    color: "black",
  },
  listItem: {
    lineHeight: "2em",
  },
  link: {
    ...gStyles.fontRegular,
    fontSize: ".875rem",
    cursor: "pointer",
    lineHeight: "inherit",
    color: "#555",
    ":focus": {
      textDecoration: "underline",
    },
  },
};

const FooterColumn = (props) => {
  const { links } = props;
  const { styles } = useStyles(baseStyles, props);
  const user = useLoggedInUser();

  const execAction = useCallback(
    (func) => (e) => {
      if (func) {
        e.preventDefault();
        func(e);
      }
    },
    []
  );

  const renderLink = (linkProps, index) => {
    const { title, to, action, external, copyright, permission } = linkProps;

    if (permission && !userHasPermission(user, permission)) {
      return null;
    }

    if (index === 0 && !copyright) {
      return (
        <li key={index} className={css(styles.headerLink)}>
          {title}
        </li>
      );
    }

    let linkComponent = null;
    const linkAttrs = {
      onClick: execAction(action),
      onKeyDown: (e) => {
        if (action && eventIsFieldTrigger(e)) {
          e.preventDefault();
          action(e);
        }
      },
      className: css(styles.link),
      title,
    };

    const linked = to || action;

    if (linked) {
      linkComponent =
        external || !to ? (
          <a
            {...linkAttrs}
            href={to || "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {title}
          </a>
        ) : (
          <Link
            {...linkAttrs}
            data-id={`footer-link-${title.toLowerCase()}`}
            to={to}
          >
            {title}
          </Link>
        );
    }

    return (
      <li
        key={index}
        className={css(
          styles.listItem,
          index === 0 && copyright && styles.link
        )}
      >
        {linked ? linkComponent : title}
      </li>
    );
  };

  return (
    <ul className={css(styles.linksColumn)}>
      {links.filter((i) => !!i).map(renderLink)}
    </ul>
  );
};

FooterColumn.propTypes = {
  links: PropTypes.array.isRequired,
};
FooterColumn.defaultProps = {
  permission: null,
};

export default memo(FooterColumn);
