import { css } from "aphrodite";
import { useCallback } from "react";
import { memo } from "react";

import StandardButton from "components/Buttons/StandardButton.js";
import ContentContainer from "components/Common/Layout/ContentContainer";

import PodchaserBrandLogo from "../../Branding/PodchaserBrandLogo";
import AccountFooterLinks from "./AccountFooterLinks";
import CompanyFooterLinks from "./CompanyFooterLinks";
import FeaturesFooterLinks from "./FeaturesFooterLinks";
import PodcastFooterLinks from "./PodcastFooterLinks";
import PolicyAndTermsFooterLink from "./PolicyAndTermsFooterLink";
import ResourcesFooterLink from "./ResourcesFooterLink";
import SocialMedia from "./SocialMedia";
import SolutionsFooterLinks from "./SolutionsFooterLinks";
import UnlockProFooterBanner from "./UnlockProFooterBanner";

import modalActions from "actions/modals";
import sendGAEvent from "utils/sendGAEvent";

import useActionCreators from "hooks/useActionCreators";
import { useStyles } from "hooks/useStyles";
import useUserHasPro from "hooks/useUserHasPro";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import ScreenSizes from "styles/ScreenSizes";

const baseStyles = {
  outer: {
    position: "relative",
    background: "#fff",
    minHeight: "88rem",

    [ScreenSizes.mdAndAbove]: {
      minHeight: "35rem",
    },
  },
  svg: {
    margin: "1rem auto -2rem",
    display: "block",
    width: "75%",

    [ScreenSizes.mdAndAbove]: {
      width: "60%",
    },
    [ScreenSizes.xlAndAbove]: {
      position: "absolute",
      margin: 0,
      bottom: 0,
      left: "1rem",
      width: "20%",
      height: "auto",
    },
    [ScreenSizes.xxxlAndAbove]: {
      left: "auto",
      right: "1rem",
    },
  },
  contentContainer: {
    backgroundColor: "#FFFFFF",
    marginTop: "auto",
    zIndex: 10,
    paddingLeft: "1.5rem",
    [ScreenSizes.mdAndBelow]: {
      backgroundImage: "none",
      paddingBottom: 0,
    },
  },
  content: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    color: colours.oldSecondary,
    minHeight: 0,
    minWidth: 0,
    flex: "none",
    paddingBottom: "2.5rem",
  },
  hr: {
    height: "0.063rem",
    backgroundColor: colours.lightGreyBorder,
    border: "none",
    marginBottom: "3rem",
    [ScreenSizes.mdAndAbove]: {
      marginBottom: "1.5rem",
    },
  },
  bottomWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [ScreenSizes.mdAndAbove]: {
      flexDirection: "row",
      gap: "3rem",
    },
  },
  podchaserIconColumn: {
    display: "flex",
    marginRight: 0,
    width: "100%",
    marginBottom: "2rem",
  },
  CTAWrapper: {
    display: "flex",
    gap: "0.6rem",
    flexDirection: "row-reverse",
    justifyContent: "flex-end",
    [ScreenSizes.mdAndAbove]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      alignItems: "start",
      marginTop: 0,
    },
  },
  CTAWrapperWPermissions: {
    display: "flex",
    alignItems: "start",
    [ScreenSizes.mdAndAbove]: {
      display: "block !important",
    },
  },
  podchaserLogo: {
    marginRight: "3.125rem",
    display: "flex",
    marginBottom: "1.2rem",
    width: "auto",
    height: "auto",
    maxWidth: 300,
    maxHeight: 38,

    [ScreenSizes.lgAndBelow]: {
      display: "flex",
      marginRight: 0,
      width: "100%",
      justifyContent: "center",
      marginBottom: "3rem",
    },
  },
  logoAndLearnMoreWrapper: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  podchaserInfo: {
    maxWidth: "27.188rem",
    minHeight: "5rem",
    marginTop: 0,
    lineHeight: "2rem",
    fontSize: "0.813rem",
    [ScreenSizes.lgAndAbove]: {
      maxWidth: "24.188rem",
    },
    [ScreenSizes.mdAndAbove]: {
      fontSize: "1rem",
    },
  },
  CTAAndoSocialsWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "2.4rem",
  },
};

const upgradeButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: `${colours.white} !important`,
    borderRadius: "1.188rem",
    background:
      "linear-gradient(90deg, rgb(43, 0, 143) 0%, rgb(167, 0, 176) 100%)",
    maxWidth: "9.5rem",
    margin: "unset",
    fontWeight: "bolder",
    whiteSpace: "nowrap",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    [ScreenSizes.mdAndAbove]: {
      justifySelf: "end",
    },
  },
};

const downloadButtonStyles = {
  button: {
    ...gStyles.avalonBold,
    fontSize: "0.813rem",
    color: colours.black,
    borderRadius: "1.188rem",
    padding: "1.2rem",
    maxWidth: "12.5rem",
    fontWeight: "bolder",
    maxHeight: "2.7rem",
    minWidth: "8rem",
    // whiteSpace: "nowrap",
  },
};

const Footer = (props) => {
  const { styles } = useStyles(baseStyles, props);
  const { showModal } = useActionCreators({
    showModal: modalActions.showModal,
  });
  const hasProPermission = useUserHasPro();

  const onDemoRequestSubmitted = useCallback(() => {
    sendGAEvent({
      action: "submittedProLeadForm",
      context: "Footer",
    });
  }, []);

  const handleUpgradeToPro = useCallback(() => {
    sendGAEvent({
      action: "openedProLeadFormModal",
      context: "Footer",
    });

    showModal("proLeadForm", {
      onDemoRequestSubmitted,
    });
  }, [showModal, onDemoRequestSubmitted]);

  const handleDownloadAppClick = useCallback(() => {
    sendGAEvent({
      action: "CTAClickDownlaodApp",
      context: "FooterDownlaodAppCTA",
    });
  }, []);

  return (
    <div className={css(styles.outer)}>
      {!hasProPermission && <UnlockProFooterBanner />}
      <ContentContainer
        dataId="footer-container"
        style={styles.contentContainer}
      >
        <div className={css(styles.content)}>
          <PodcastFooterLinks />
          <FeaturesFooterLinks />
          <SolutionsFooterLinks />
          <AccountFooterLinks />
          <CompanyFooterLinks />
          <ResourcesFooterLink />
        </div>
        <hr className={css(styles.hr)} />
        <div className={css(styles.bottomWrapper)}>
          <div className={css(styles.logoAndLearnMoreWrapper)}>
            <div
              className={css(styles.podchaserIconColumn, styles.podchaserLogo)}
            >
              <PodchaserBrandLogo
                large
                dark
                width="auto"
                height={38}
                imgProps={{
                  loading: "lazy",
                }}
              />
            </div>

            <p className={css(styles.podchaserInfo)}>
              Podchaser is the ultimate destination for podcast data, search,
              and discovery.{" "}
              <b>
                <a href="/about" title="About Podchaser">
                  Learn More
                </a>
              </b>
            </p>
          </div>
          <div className={css(styles.CTAAndoSocialsWrapper)}>
            <div
              className={css(
                hasProPermission
                  ? styles.CTAWrapperWPermissions
                  : styles.CTAWrapper
              )}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://support.podchaser.com/en/articles/5673590-how-to-install-the-podchaser-app"
              >
                <StandardButton
                  label="Download the App"
                  variation="white"
                  flat
                  customStyles={downloadButtonStyles}
                  onClick={handleDownloadAppClick}
                />
              </a>
              {!hasProPermission && (
                <StandardButton
                  label="Upgrade to Pro"
                  onClick={handleUpgradeToPro}
                  flat
                  customStyles={upgradeButtonStyles}
                  variation="none"
                />
              )}
            </div>

            <SocialMedia />
          </div>
        </div>
        <PolicyAndTermsFooterLink />

        <style>#ot-sdk-btn.ot-sdk-show-settings {}</style>
        {/*<!-- OneTrust Cookies Settings button start -->*/}
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">
          Cookie Settings
        </button>
        {/*<!-- OneTrust Cookies Settings button end -->*/}
      </ContentContainer>
    </div>
  );
};

export default memo(Footer);
